import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { NavBar, TopBar, ChatBar } from './components';

import navigationConfig from 'layouts/User/components/NavBar/navigationConfig';
import * as NoDom from 'NoDom';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import { storageTokenKey, storageUserKey } from "../../constants";
import {login} from 'store/modules/auth/actions';
import useRouter from 'utils/useRouter';
import { getProduct } from 'services/product.service';
import { setProducts } from 'store/modules/products/actions';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const router = useRouter();

  useEffect(()=>{
    const current_user = JSON.parse(localStorage.getItem(storageUserKey));
    if (current_user) {
      dispatch(login({
        user: current_user.user,
        accessToken: current_user.accessToken,
        isAdmin: false
      }));
      getProduct().then((result=>{
        dispatch(setProducts(result.products));
      }))
    } else {
      router.history.push('/auth/login');
    }
  }, [])

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
