import { post, get } from "./request.service"
import { store } from 'store'


export const getProduct = () => {
  return get('/api/product/');
}

// export const reloadS3 = () => {
//   return get('/api/s3/getfiles');
// }

export const downloadProduct = (productId) => {
  return get(`/api/s3/download/${productId}`)
}