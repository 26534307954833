import { combineReducers } from 'redux';

import auth from './auth/reducer';
import users from './users/reducer';
import products from './products/reducer';

export default () => combineReducers({
    auth,
    users,
    products
})
  