export const baseURL = window.location.hostname === 'localhost' ? 'http://localhost:5000' : ''
export const storageTokenKey = "ACCESS_TOKEN"
export const storageUserKey = "CURRENT_USER"
export const storageCartKey = "CARTS"
export const storageNavKey = "NAV_CATEGORY"

const privatePath  = [
  '/account', '/order', '/shipping', '/credithist'
]

export const isPrivateRoute = (path) => {
  return privatePath.includes(path);
}
export const noFooters = [
  '/account/edit',
]
