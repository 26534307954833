/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
// import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

import UserLayout from './layouts/User';
import AdminLayout from './layouts/Admin';

import { store } from 'store'
const state = store.getState();

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/user/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('view_user/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('view_user/Register'))
      },
      {
        path: '/auth/forgotpass',
        exact: true,
        component: lazy(() => import('view_user/ForgotPassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('view_user/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('view_user/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('view_user/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    // route: '*',
    path: '/user',
    component: UserLayout,
    routes: [
      {
        path: '/user/dashboard',
        exact: true,
        component: lazy(()=>import('view_user/Dashboard'))
      },
      {
        path: '/user/account',
        exact: true,
        component: lazy(()=>import('view_user/Settings'))
      },
      {
        path: '/user/account/:tab',
        exact: true,
        component: lazy(()=>import('view_user/Settings'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/admin/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/admin/auth/login',
        exact: true,
        component: lazy(() => import('view_admin/Login'))
      },      
      {
        path: '/admin/auth/forgotpass',
        exact: true,
        component: lazy(() => import('view_admin/ForgotPassword'))
      },
      // {
      //   path: '/auth/register',
      //   exact: true,
      //   component: lazy(() => import('view_user/Register'))
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    // route: '*',
    path: '/admin',
    component: AdminLayout,
    routes: [
      {
        path: '/admin/users',
        exact: true,
        component: lazy(() => import('view_admin/ManageUsers'))
      },
      {
        path: '/admin/stateofuser',
        exact: true,
        component: lazy(() => import('view_admin/Users'))
      },
      {
        path: '/admin/account',
        exact: true,
        component: lazy(()=>import('view_admin/Settings'))
      },
      {
        path: '/admin/account/:tab',
        exact: true,
        component: lazy(()=>import('view_admin/Settings'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
];

export default routes;
