import { SET_USERS, SET_NAVI_USERS } from './types'

const initialState = {
  users: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload
      }
    case SET_NAVI_USERS:
      return {
        ...state,
        naviUsers: action.payload
      }
    default:
      return state
  }
}
