import { SET_USERS, SET_NAVI_USERS } from './types'

export const setUsers = (users) => dispatch => {
    dispatch({
      type: SET_USERS,
      payload: users
    });
}

export const setNaviUsers = (naviUsers) => dispatch => {
  dispatch({
    type: SET_NAVI_USERS,
    payload: naviUsers
  });
}