import { post, get } from "./request.service"

export const getUsers = () => {
    return get('/api/user')
}

export const updateUser = async ({ name, email}) => {
  try {
    const result =  await post(`/api/user/update`, { name, email });
    return result;
  } catch (error) {
    throw error
  }
}

export const deleteUser = (id) => {
  return get(`/api/user/delete/${id}`)
}