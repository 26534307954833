/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Menu,
  MenuItem
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import axios from 'utils/axios';
import useRouter from 'utils/useRouter';
import { PricingModal, NotificationsPopover } from 'components';
// import { logout } from 'actions';
import { logout } from 'store/modules/auth/actions'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },  
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  topButtonsGroup : {
    marginLeft: '50px'
  },
  topButtons: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    // const fetchNotifications = () => {
    //   axios.get('/api/account/notifications').then(response => {
    //     if (mounted) {
    //       setNotifications(response.data.notifications);
    //     }
    //   });
    // };

    // fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  // custome event
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAccount = () => {
    history.push('/user/account');
    setAnchorEl(null);
  }

  const handleClickLogout = () => {
    history.push('/auth/login');
    dispatch(logout());    
    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="menu-appbar"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleClickAccount}>My account</MenuItem>
      <MenuItem onClick={handleClickLogout}>Log out</MenuItem>
    </Menu>
  );

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink> */}
        
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Tooltip title="Menu">
            <IconButton 
                aria-label="display more actions" 
                aria-controls="menu-appbar"
                aria-haspopup="true"
                edge="end" 
                color="inherit"
                onClick={handleMenu}
            >
                <MoreIcon />
            </IconButton>
        </Tooltip>        
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
