export const toTree = (arr) => {
    let arrMap = new Map(arr.map(item => [item.id, item]));
    let tree = [];
  
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
  
      if (item.parentID) {
        let parentItem = arrMap.get(item.parentID);
  
        if (parentItem) {
          let { children } = parentItem;
  
          if (children) {
            parentItem.children.push(item);
          } else {
            parentItem.children = [item];
          }
        }
      } else {
        tree.push(item);
      }
    }  
    return tree;
}

export const treeTheme = {
  tree: {
      base: {
          listStyle: 'none',
          backgroundColor: '#FFFFFF',
          margin: 0,
          padding: 0,
          color: '#546E7A',
          fontFamily: 'lucida grande ,tahoma,verdana,arial,sans-serif',
          fontSize: '15px'
      },
      node: {
          base: {
              position: 'relative'
          },
          link: {
              cursor: 'pointer',
              position: 'relative',
              padding: '0px 5px',
              display: 'block'
          },
          activeLink: {
              background: '#C3C3C3'
          },
          toggle: {
              base: {
                  position: 'relative',
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginLeft: '-5px',
                  height: '24px',
                  width: '24px'
              },
              wrapper: {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  margin: '-7px 0 0 -7px',
                  height: '14px'
              },
              height: 14,
              width: 14,
              arrow: {
                  fill: '#546E7A',
                  strokeWidth: 0
              }
          },
          header: {
              base: {
                  display: 'inline-block',
                  verticalAlign: 'top',
                  color: '#546E7A'
              },
              connector: {
                  width: '2px',
                  height: '12px',
                  borderLeft: 'solid 2px black',
                  borderBottom: 'solid 2px black',
                  position: 'absolute',
                  top: '0px',
                  left: '-21px'
              },
              title: {
                  lineHeight: '24px',
                  verticalAlign: 'middle'
              }
          },
          subtree: {
              listStyle: 'none',
              paddingLeft: '19px'
          },
          loading: {
              color: '#E2C089'
          }
      }
  }
};